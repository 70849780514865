<template>
    <setting-layout title="Add Card">
        <v-container>
            <v-row justify="center">
                <credit-card
                    :data="model"
                    :flipped="flipped"
                />
            </v-row>
            <v-row class="mt-4">
                <v-col>
                    Payment Details
                </v-col>
            </v-row>
            <v-row class="mt-4">
                <v-col>
                    <text-field v-model="model.number" v-mask="'#### #### #### ####'" placeholder="Card Number"
                                @focus="flipped = false"></text-field>
                </v-col>
            </v-row>
            <v-row class="mt-4">
                <v-col>
                    <text-field v-model="model.holder" placeholder="Name on Card" @focus="flipped = false"
                                @input="model.name=model.holder.toUpperCase()"></text-field>
                </v-col>

            </v-row>
            <v-row class="mt-4">
                <v-col cols="6">
                    <text-field v-model="model.expires" placeholder="Expire Date" @focus="flipped = false"
                                v-mask="'##/##'"/>
                </v-col>
                <v-col cols="6">
                    <text-field v-model="model.cvv" placeholder="Security Code" @focus="flipped=true" v-mask="'###'"/>
                </v-col>
            </v-row>
            <v-row justify="center" class="mt-7 ml-5 mr-5">
                <gradient-button block :loading="$store.getters.loadingBtn(['setting', 'newCardBtn'])" @click="save">{{ $t('dialogs.add') }}</gradient-button>
            </v-row>
        </v-container>


    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import CreditCard from "@/components/app/setting/CreditCard";
import TextField from "@/components/app/form/TextField";
import GradientButton from "@/components/app/button/GradientButton";
import {mapActions} from "vuex";

export default {
    name: "AddCard",
    data: () => ({
        model: {
            number: '',
            holder: '',
            expires: '',
            cvv: ''
        },
        flipped: false
    }),
    methods: {
        ...mapActions(['sendNewCard']),
        save(){
            this.sendNewCard({model: this.model})
                .then(() => this.$router.go(-1))
        }
    },
    components: {GradientButton, TextField, CreditCard, SettingLayout}
}
</script>

<style scoped>

</style>
